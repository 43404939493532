import StepHeader from './StepHeader.jsx'
import AreaInput from './AreaInput.jsx'
import FloorSelection from './FloorSelection.jsx'
import NLDKSelection from './NLDKSelection.jsx'
import ParkingSelection from './ParkingSelection.jsx'
import AutoGenerateButton from './AutoGenerateButton.jsx'
import RequiredRoomSelection from './RequiredRoomSelection.jsx';
import Result from './Result.jsx';
import ContactContents from './ContactContents.jsx'
import Message from './Message.jsx'
import Affiliate from './Affiliate.jsx'
import Step1Provider from '../contexts/Step1Context.jsx'
import Step2Provider from '../contexts/Step2Context.jsx'
import Step3Provider from '../contexts/Step3Context.jsx'
import ResultProvider from '../contexts/ResultContext.jsx'


const RoomSizeSelector = () => {

  return (
    <>
      <Step1Provider>
        <Step2Provider>
          <Step3Provider>
            <ResultProvider>
              <StepHeader stepNumber='1' description='建築できる範囲を入力してください' />
              <div id='step1' className='step-container'>
                <AreaInput />
              </div>
              <div className="grid-size-annotation-wrapper">
                <p className="grid-size-annotation-text">※ 1マスは 1m x 1m です</p>
              </div>

              <StepHeader stepNumber='2' description='希望の条件を選んでください' />
              <div id='step2' className='step-container'>
                <FloorSelection />
                <NLDKSelection />
                <ParkingSelection />
              </div>

              <StepHeader stepNumber='3' description='こだわりたい部分を選んでください' />
              <div id='step3' className='step-container'>
                <RequiredRoomSelection />
              </div>

              <StepHeader stepNumber='4' description='間取りを生成する' />
              <div id='step4' className='step-container step4-container'>
                <AutoGenerateButton />
                <Result />
              </div>
            </ResultProvider>
            <div className="communication-wrapper">
              <Message />
              <ContactContents />
            </div>
            <Affiliate />
          </Step3Provider>
        </Step2Provider>
      </Step1Provider>
    </>
  );
}

export default RoomSizeSelector;