import '../css/FloorSelection.css'
import SelectionTitleLabel from './SelectionTitleLabel'
import { useStep2 } from '../contexts/Step2Context.jsx'

const FloorSelection = () => {
  // グレーアウト版
  const { nFloor, setNFloor } = useStep2();

  const FLOOR_SELECT_OPTIONS = [1, 2, 3]

  const handleKeyDown = (e, value) => {
    if ((e.key === 'Enter' || e.key === ' ')) {
      e.preventDefault()
      setNFloor(value)
    }
  }
  return (
    <div className='container-floor-selection'>
      <SelectionTitleLabel title='階数' />
      <div className='button-container-floor-selection'>
      { FLOOR_SELECT_OPTIONS.map((floor) => (
        <div key={floor}>
          <input 
            type='radio'
            name='floor-select' 
            className={Number(nFloor)===floor ? 'checked-floor-selection' : undefined}
            value={floor}
            id={`floor-select-${floor}`}
            checked={Number(nFloor)===floor}
            onChange={floor===1 ? (e) => setNFloor(e.target.value) : undefined}
            disabled={floor>=2}
          />
          <label 
            htmlFor={`floor-select-${floor}`}
            className={floor===1 ? 'input-label-floor-selection' : 'disable-input-label-floor-selection'}
            onClick={floor===1 ? (e) => setNFloor(e.target.value) : undefined}
            data-value={floor}
            disabled={floor>=2}
            tabIndex={floor===1 ? "0" : "-1"}
            onKeyDown={(e) => handleKeyDown(e, floor)}
          >
            <img 
              className='button-image-floor-selection'
              src={floor===1 ? `/images/${floor}-storey.png` : `/images/disable-${floor}-storey.png`} 
              alt={`${floor}storey house img`}
              data-value={floor}
            />
            <div 
              className='button-text-floor-selection' 
              data-value={floor}
            >
              {floor}階建て
            </div>
          </label>
        </div>
      )) }
      </div>
    </div>
  )
}

export default FloorSelection