import "../css/Affiliate.css"

const Affiliate = () => {
  return (
    <div className="container-affiliate">
      <div className="upper-affiliate">
        <a href="https://townlife-aff.com/link.php?i=61a5e1e44f96e&m=66e0449b37c27"><img src="https://townlife-aff.com/data.php?i=61a5e1e44f96e&m=66e0449b37c27" width="728" height="90" border="0" /></a>
      </div>
      <div className="lower-affiliate">
        <a href="https://townlife-aff.com/link.php?i=5db288fb8bded&m=66e0449b37c27"><img src="https://townlife-aff.com/data.php?i=5db288fb8bded&m=66e0449b37c27" width="728" height="90" border="0" /></a>
      </div>
    </div>
  );
}

export default Affiliate;